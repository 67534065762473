import {SideMenu} from "../components/Sidemenu";
import {NavigationView} from "../components/PageRoot";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {GuardedRoute} from "../auth/GuardedRoute";
import {Customers} from "./+customers/Customers";
import React from "react";
import {CustomerDetails} from "./+customers/CustomerDetails";
import {Accounts} from "./+accounts";
import {Targets} from "./+targets/Targets";
import {TargetDetails} from "./+targets/TargetDetails";
import {CookieList} from "./+cookies/CookieList";


export const Main = () => {
    let {url} = useRouteMatch();
    return <NavigationView sidebar={<SideMenu/>}>
        <Switch>
            <Route exact path={url} render={() => <Redirect to={`${url}/targets`}/>}/>
            <GuardedRoute exact path={`${url}/customers`} component={Customers}/>
            <GuardedRoute path={`${url}/customers/:customerId`} component={CustomerDetails}/>
            <GuardedRoute path={`${url}/customers/:customerId/create`} component={CustomerDetails}/>
            <GuardedRoute exact path={`${url}/accounts`} component={Accounts}/>
            <GuardedRoute exact path={`${url}/targets`} component={Targets}/>
            <GuardedRoute exact path={`${url}/cookies`} component={CookieList}/>
            <GuardedRoute exact path={`${url}/targets/:targetId`} component={TargetDetails}/>
        </Switch>
    </NavigationView>
}
