import styled from "styled-components";
import {PropsWithChildren, ReactNode, useMemo} from "react";
import {BarLoader} from "./BarLoader";

export const PageTitle = styled.h2`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colorAccent};
  font-family: "Inter", sans-serif;
  font-size: 18px;
  position: relative;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 30px;
`
export const PageWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 30px;
  overflow: auto;
`
const LoaderContainer = styled.div`
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
`


export const Page = (props: PropsWithChildren<{ loading?: boolean, title: string | ReactNode }>) => {
    const loading = useMemo(() => props.loading ?? false, [props.loading])
    return <PageWrapper>
        <PageTitle>
            {props.title}
            <LoaderContainer><BarLoader loading={loading} height={4} width="100%"/></LoaderContainer>
        </PageTitle>
        {props.children}
    </PageWrapper>
}
