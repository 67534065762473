import {API, ApiKey} from "../../api/api";
import {useAuthContext} from "../../auth/auth";
import React, {useCallback, useState} from "react";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {CookiesDialogTitle, DialogText} from "../../components/QookiesDialogTitle";
import {Input} from "../../components/Input";
import {Button} from "../../components/Button";

const initialState = '';
export const CreateApiKeyModal = (props: { customer: number, show: boolean, postCreate: (key: ApiKey) => void, onHide: () => void }) => {
    const {token} = useAuthContext()
    const [target, set] = useState(initialState)
    const [isSending, setIsSending] = useState(false)

    const sendRequest = useCallback(async () => {
        if (isSending) return;
        setIsSending(true)
        const response = await API.createApiKey({customer: props.customer, target}, token!)
        setIsSending(false)
        props.onHide();
        props.postCreate(response.data);
        set(initialState)
    }, [isSending, target])

    return (<Dialog open={props.show} onClose={props.onHide}>
        <DialogContent>
            <CookiesDialogTitle disableTypography={true}>Create API Key</CookiesDialogTitle>
            <DialogContent>
                <DialogText>
                    Provide a target name for this API key. The target identifies the cookies and styling a consent
                    banner uses. You can create multiple API keys for the same target, but they will have the same
                    styling and cookie listing.
                </DialogText>
                <Input type="text" placeholder="Configuration Target" value={target}
                       onChange={(event) => set(event.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button style={{flex: '0 0 auto'}} borderless secondary
                        onClick={() => props.onHide()}>Cancel</Button>
                <Button style={{flex: '0 0 auto'}} borderless
                        onClick={sendRequest}>Create</Button>
            </DialogActions>
        </DialogContent>
    </Dialog>)

}
