import {animated} from "react-spring";
import styled from "styled-components";
import {transparentize} from "polished";

export const ListItem = animated(styled.li`
  &:hover {
    background-color: ${props => transparentize(.83)(props.theme.colorAccent)};
  }

  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    border-top: 1px solid ${props => props.theme.colorDivider};
  }

  &:first-of-type:after {
    content: unset;
  }

  border-radius: 5px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
  display: flex;

  &:first-of-type {
    border-top: none;
  }

  align-items: center;
  padding: 12px 24px;
`)

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`
