import {Page} from "../../components/Page";
import {useCookies} from "../../api/api";
import {useDialogControl, useListTransition} from "../../globals";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {animated, useTransition} from "react-spring";
import {CookieEntry, Label, Value} from "./styles";
import {List} from '../../components/list';
import {Panel} from "../+targets/styles";
import {CookieProvider} from "../../components/CookieProvider";
import {totalmem} from "os";
import {easeInOut} from "polished";
import {Button} from "../../components/Button";
import {CreateCookieModal} from "./CreateCookieModal";
import {Fragment} from "react";

const Cookie = animated(styled.div`
  border-radius: 5px;
  border: 1px solid #1295d4;
  background-color: #ebf2f5;
  padding: 12px;
`)
const CookieGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`
const CookieHeader = styled.h3`
  font-family: "Inter", sans-serif;
  font-size: 14px;
`
const Description = styled.p`
  font-size: 12px;
  color: #5e5e5e;
`

interface PaginationProps {
    totalItems: number;
    activePage: number;
    onChange: (page: number) => void;
}

const StyledPaginator = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 24px 0;
  margin: 0;
`

const PageItem = styled.li<{ active?: boolean }>`
  font-family: "Inter", sans-serif;
  font-size: 15px;
  width: 36px;
  height: 36px;
  color: ${props => props.active ? '#fff' : props.theme.colorPrimary};
  font-weight: 600;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: background-color 100ms ${easeInOut('quad')}, border-color 100ms ${easeInOut('quad')};
  background-color: ${props => props.active ? props.theme.colorAccent : 'transparent'};
  border: 2px solid transparent;
  border-radius: 100%;

  &:hover {
    border-color: ${props => props.theme.colorAccent};;
  }
`

const Pagination = (props: PaginationProps) => {
    const [page, set] = useState(props.activePage)
    useEffect(() => {
        set(props.activePage)
    }, [props.activePage])

    const changePage = useCallback((page) => {
        set(page)
        props.onChange(page)
    }, [set])
    const pageSize = 20;
    const numberOfPages = useMemo(() => Math.ceil(props.totalItems / pageSize), [props.totalItems, pageSize]);
    const pages = useMemo(() => {

        const pages = []
        for (let i = 1; i <= numberOfPages; i++) {
            pages.push(i)
        }
        return pages
    }, [numberOfPages])
    return <StyledPaginator>
        {pages.map(p => <PageItem onClick={() => changePage(p)} active={page === p}>{p}</PageItem>)}
    </StyledPaginator>
}

export const CookieList = () => {
    const [added, add] = useState<any[]>([])
    const [cookies, loading] = useCookies([added])
    const [page, setPage] = useState(1);
    const cookiesToShow = useMemo(() => cookies.sort((c1,c2) => c1.name.replaceAll('_','').localeCompare(c2.name.replaceAll('_',''))).slice((page - 1) * 10, (page - 1) * 10 + 10), [page, cookies, added])
    const transition = useTransition(cookiesToShow, {
            key: c => c.name,
            from: {opacity: 0, translateX: -20},
            initial: {opacity: 0, translateX: -20},
            leave: {opacity: 0, translateX: -20},
            enter: {opacity: 1, translateX: 0},
            trail: (200 / cookiesToShow.length),
        }
    )
    const dialogControl = useDialogControl()
    return <Fragment><Page title="Cookies">
        <Panel title="Manage Cookies" description="Add or edit cookie definitions" collapse={true}
               actions={<Button onClick={dialogControl.open}>New Cookie</Button>}>
            <List>
                {cookiesToShow.map((item) => <CookieEntry key={item.name}>
                    <Label for="name">Cookie Name</Label>
                    <Label for="provider">Provider</Label>
                    <Label for="category">Categories</Label>
                    <Value id="name">{item.name}</Value>
                    <Value id="provider"><CookieProvider>{item.provider}</CookieProvider></Value>
                    <Value id="category">{item.categories.join(', ')}</Value>
                </CookieEntry>)}
            </List>
            <Pagination activePage={page} totalItems={cookies.length} onChange={(newPage) => setPage(newPage)}/>
        </Panel>
    </Page>
        <CreateCookieModal show={dialogControl.handle.show} postCreate={(c) => add(p => [...p, c])}
                           onHide={dialogControl.handle.onHide}/>
    </Fragment>
}
