import {createContext, useCallback, useContext, useMemo, useState} from "react";
import {decode} from 'jsonwebtoken';
import axios from "axios";

export interface AuthUser {
    sub: number;
    rol: string;
}

export type NoUser = null;
export type JwtToken = string;

export interface AuthContextType {
    logout: () => void;
    user: AuthUser | NoUser;
    token: JwtToken | null;
}

export const AuthContext = createContext<AuthContextType>({
    user: null, token: '', logout: () => {
    }
})

export function useAuthContext() {
    return useContext<AuthContextType>(AuthContext)
}

export function useAuth() {
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'))
    const [user, setUser] = useState(() => {
        return token == null ? null : decode(token) as AuthUser
    })

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        setToken(null)
        setUser(null)
    }, [user, token])

    const memoizedContext = useMemo(() => ({
        user, token, logout
    }), [user, token, logout])

    return (props: any) => <AuthContext.Provider value={memoizedContext}>{props.children}</AuthContext.Provider>
}


