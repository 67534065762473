import styled, {css} from "styled-components";
import {animated} from "react-spring";
import {PropsWithChildren, ReactElement} from "react";
import {lighten, transparentize} from "polished";

export const SwitchLabel = styled.label`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 4px 12px;
`

export const Label = styled.div`
  color: ${transparentize(.3)('#282c34')};
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 13px;
`
export const Value = styled.div`
  color: #282c34;
  font-size: 15px;
`

const StyledPanel = styled.div`
  &:first-of-type {
    border: none;
  }

  border-bottom: 2px solid ${props => props.theme.colorDivider};
    //background-color: ${lighten(.12)('#D3DFE4')};
  padding-bottom: 10px;
  margin-bottom: 20px;
`
const PanelActions = styled.div`
  background-color: ${lighten(.10)('#D3DFE4')};
  padding: 16px 0;
`
const PanelContent = styled.div<{ collapse?: boolean }>`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colorDivider};;
  ${props => !props.collapse && css`
    padding: 12px 24px;
  `}
`
export const Panel = (props: PropsWithChildren<{ description?: string, title: string, collapse?: boolean, actions?: ReactElement }>) => {
    return <StyledPanel>
        <TitleBar>
            {props.title}
            <p>{props.description}</p>
        </TitleBar>

        <PanelContent collapse={props.collapse}>
            {props.children}
        </PanelContent>
        {props.actions && <PanelActions>
            {props.actions}
        </PanelActions>}
    </StyledPanel>
}

export const TitleBar = styled.h2`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  color: ${props => props.theme.colorPrimary};
  line-height: 24px;
  font-weight: 800;

  p {
    font-size: 15px;
    margin: 0 0 0 1px;
    padding: 0;
    color: ${props => transparentize(.3)(props.theme.colorPrimary)};
    font-weight: normal;
    font-family: 'Questrial', sans-serif;
  }
`
export const LanguageWarning = styled.span`
  color: #f44336;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 14px;
`
export const ToggleButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`
export const Select = styled.select`
  font-size: 18px;
  min-width: 200px;
  border: 1px solid #D3DFE4;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 36px;
  color: #5E5E5E;
  background-color: #EBF2F5;
  padding: 4px 15px 3px;
  appearance: none;
  outline: none;
`
export const SplitPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`

export const TargetName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
  font-family: 'Questrial', sans-serif;
  width: 135px;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  color: ${props => props.theme.colorSeparator};
  margin-right: 15px;
  border: 1px solid ${props => props.theme.colorSeparator};
`

export const CellHeader = styled.div`
  color: ${props => props.theme.colorAccent};
`
export const Overview = animated(styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`)
export const Cell = styled.div<{ title?: string }>`
  display: flex;
  flex-direction: column;
  font-weight: 400;
`
