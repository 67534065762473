import {Redirect, Route} from "react-router-dom";
import React from "react";
import {RouteComponentProps, RouteProps} from "react-router";
import {isLoggedIn} from "axios-jwt";

export const GuardedRoute = (props: RouteProps & { component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>, path: string | string[] }) => {
    const Component = props.component
    return <Route path={props.path} render={(props) => (
            isLoggedIn()
                ? <Component {...props} />
                : <Redirect to='/login'/>
        )}/>
}
