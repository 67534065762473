import styled from "styled-components";

export const Input = styled.input`
  border-radius: 5px;
  border: 1px solid #1295d4;
  background-color: #ebf2f5;
  color: #021e3f;
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  padding: 20px 30px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  outline: none;
  width: 100%;
  min-width: 474px;
  margin-bottom: 25px;
`;

export const Select = styled.select`
  appearance: none;
  border-radius: 5px;
  border: 1px solid #1295d4;
  background-color: #ebf2f5;
  color: #021e3f;
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  padding: 20px 30px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  outline: none;
  width: 100%;
  min-width: 474px;
  margin-bottom: 25px;
`;
