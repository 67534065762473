import {SideMenu} from "./Sidemenu";
import styled from "styled-components";
import {ReactNode} from "react";

export interface NavigationViewProps {
    sidebar: ReactNode
    children: ReactNode
}

const NavigationRoot = styled.div`
height:100%;
display:flex;
`
const SidebarWrapper = styled.div`
width:270px;
flex: 0 0 270px;
`
export const NavigationView = (props: NavigationViewProps) => {
    return <NavigationRoot>
        <SidebarWrapper>
            {props.sidebar}
        </SidebarWrapper>
        {props.children}
    </NavigationRoot>
}
