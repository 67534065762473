import React, {PropsWithChildren, useCallback} from 'react';
import styled, {css} from 'styled-components';
import {transparentize} from 'polished';
import {animated, config, useSpring} from 'react-spring';

const IconHolder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type SwitchProps = PropsWithChildren<{
    checked?: boolean;
    onToggle?: () => void;
    disabled?: boolean;
}>;
const SwitchWrapper = (props: SwitchProps & { onClick: () => void }) => {
    // @ts-ignore
    return <div onClick={() => props.onClick()}>{props.children}</div>;
};
type SwitchInputProps = { isChecked?: boolean };
const SwitchInput = styled.input``;

const SwitchLabel = (props: PropsWithChildren<any>) => {
    return <label>{props.children}</label>;
};

const SwitchRoot = styled.label<{ active?: boolean; disabled?: boolean }>`
  display:block;
  box-shadow: 0 0 transparent, 0 0 transparent,
  inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  margin: 0;
  position: relative;
  line-height: normal;
  cursor: pointer;
  width: 38px;
  border-radius: 11px;
  height: 22px;
  transition: background-color 150ms ease-in-out;
  background-color: #c6c5c5;

  ${IconHolder} {
    color: rgba(0, 0, 0, 0.3);
  }

  ${props =>
          props.active &&
          css`
            background-color: ${props =>
                    transparentize(0.5)(props.theme.colorAccent)};
          `}
`;
const SwitchThumb = animated(styled.div<{ active: boolean }>`
  border-radius: 100%;
  position: absolute;
  height: 18px;
  width: 18px;
  top: 2px;
  background-color: #f1f1f1;
  transition: background-color 150ms ease-in-out;
  ${props =>
          props.active &&
          css`
            background-color: ${props => props.theme.colorAccent};
          `}
`);

const Switch = (props: SwitchProps) => {
    const thumbSpring = useSpring({
        left: props.checked ? 18 : 2,
        config: config.stiff
    });
    const toggle = useCallback(() => {
        if (props.onToggle && !props.disabled) {
            props.onToggle();
        }
    }, [props.onToggle, props.disabled]);

    return (
        <SwitchRoot active={props.checked}>
            <SwitchThumb active={props.checked} style={thumbSpring}/>
            <SwitchInput
                checked={props.checked}
                hidden
                readOnly
                onClick={toggle}
            />
        </SwitchRoot>
    );
};
export default React.memo(Switch);
