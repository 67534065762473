import {API, Customer, Target} from "../../api/api";
import React, {useCallback, useState} from "react";
import {useAuthContext} from "../../auth/auth";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {CookiesDialogTitle, DialogText} from "../../components/QookiesDialogTitle";
import {Input} from "../../components/Input";
import {Button} from "../../components/Button";

const initialState = {
    name: '',
    displayName: ''
}
export const CreateTargetDialog = (props: { show: boolean, postCreate: (key: Target) => void, onHide: () => void }) => {
    const [{name, displayName}, set] = useState(initialState)
    const [isSending, setIsSending] = useState(false)

    const sendRequest = useCallback(async () => {
        if (isSending) return;
        setIsSending(true)
        const response = await API.createTarget({name,displayName})
        setIsSending(false)
        props.onHide();
        props.postCreate(response.data);
        set(initialState)
    }, [isSending, name, displayName])

    return <Dialog open={props.show} onClose={props.onHide} aria-labelledby="form-dialog-title">
        <CookiesDialogTitle disableTypography={true} id="form-dialog-title">New Customer</CookiesDialogTitle>
        <DialogContent>
            <DialogText>
                Provide a display-friendly customer name and a contact email address.
            </DialogText>
            <Input type="text" placeholder="Target Identifier"
                   onChange={(event) => set((s) => ({...s, name: event.target.value}))}
                   value={name}/>
            <Input type="text" placeholder="Display Name"
                   onChange={(event) => set((s) => ({...s, displayName: event.target.value}))}
                   value={displayName}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onHide} borderless>
                Cancel
            </Button>
            <Button onClick={sendRequest}>
                Create
            </Button>
        </DialogActions>
    </Dialog>

}
