import {animated} from "react-spring";
import styled from "styled-components";
import {transparentize} from "polished";

export const CookieEntry = animated(styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-gap: 2px 12px;
  padding: 12px 24px;
  grid-template-areas: "namelabel providerlabel categorylabel actions"
                       "name      provider      category actions";
  border-bottom: 1px solid ${props => props.theme.colorDivider};

  &:last-of-type {
    border-bottom: none;
  }
`)
export const Label = styled.div<{ for: string }>`
  color: ${transparentize(.3)('#282c34')};
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  grid-area: ${props => props.for}label;
`
export const Value = styled.div<{ id: string }>`
  grid-area: ${props => props.id};
  color: #282c34;
  font-size: 15px;
`
