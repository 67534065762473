import styled from "styled-components";

export const AccountRole = styled.div`
  border-radius: 5px;
  font-family: 'Questrial', sans-serif;
  width: 135px;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  color: ${props => props.theme.colorSeparator};
  margin-right: 15px;
  border: 1px solid ${props => props.theme.colorSeparator};
`
