import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {CookiesDialogTitle} from "../../components/QookiesDialogTitle";
import {Button} from "../../components/Button";
import {CookieValue, useCookies} from "../../api/api";
import {animated, useTransition} from "react-spring";
import styled from "styled-components";
import {desaturate, setHue, setLightness, transparentize} from "polished";
import {Http} from "../../api/client";
import {BASE_URL} from "../../Constants";
import {BarLoader} from "../../components/BarLoader";

function hashCode(string: string) {
    var hash = 0, i, chr;
    for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

const CookiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 80vw;
  grid-gap: 24px;
`

type GridItemProps = { seed: string, added: boolean, target: string, pending?: boolean, cookie: CookieValue };

const StyledGridItem = animated(styled.div<GridItemProps>`
  background-color: #ebf2f5;
  padding: 24px 24px 16px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  position: relative;
  transition: border-color 200ms ease-in-out;
  overflow: hidden;
  border: 2px solid ${props => props.added ? props.theme.colorAccent : 'transparent'};
`)

const ItemAction = styled.a<{ disabled?: boolean }>`
  cursor:pointer;
  position: relative;
  margin-top: 12px;
  display: block;
  transition: color 150ms ease-in-out;
  color: ${props => props.disabled ? desaturate(1.0)(props.theme.colorAccent) : props.theme.colorAccent};
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 600;
`
const CookieLabel = styled.span<{ seed: string }>`
  color: ${props => setLightness(.3)(setHue(Math.pow(hashCode(props.seed), 2) % 255)('hotpink'))};
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 11px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
`
const CookieName = styled.div`
  font-family: Questrial, sans-serif;
`
const GridItem = (props: GridItemProps & { style: any }) => {
    const [loading, set] = useState(false)
    const {style, cookie, target, added, ...rest} = props
    const [isAdded, setIsAdded] = useState(added);

    const addCookie = useCallback(async (cookie: CookieValue) => {
        set(true)
        try {
            const response = await Http.patch(BASE_URL + '/v2/targets/' + props.target + '/cookies', {name: cookie.name})
            if (response.status >= 200 && response.status < 300) {
                setIsAdded(true)
            }
        } finally {
            set(false)
        }
    }, [props.target])

    const removeCookie = useCallback(async (cookie: CookieValue) => {
        set(true)
        try {
            const response = await Http.delete(BASE_URL + '/v2/targets/' + props.target + '/cookies/' + cookie.name)
            if (response.status >= 200 && response.status < 300) {
                setIsAdded(false)
            }
        } finally {
            set(false)
        }
    }, [props.target])
    return <StyledGridItem style={style} {...rest} added={isAdded}>
        <CookieName>{cookie.name}</CookieName>
        {cookie.label && <CookieLabel seed={cookie.label}>{cookie!.label}</CookieLabel>}
        {isAdded ? <ItemAction onClick={() => removeCookie(cookie)}>Remove Cookie</ItemAction> :
            <ItemAction disabled={loading} onClick={loading ? void(0) : () => addCookie(cookie)}>Add Cookie</ItemAction>}

        <div style={{width: '100%', position: 'absolute', bottom: 0, left: 0}}>
            <BarLoader loading={loading} height={4} width="100%"/>
        </div>
    </StyledGridItem>
}

export const ManageCookiesModal = (props: { show: boolean, onHide: () => void, target: string, targetCookies: CookieValue[] }) => {
    const [cookies, loading] = useCookies([]);
    const [addedCookies, set] = useState<string[]>([]);
    useEffect(() => {
        set(props.targetCookies.map(c => c.name))
    }, [props.targetCookies])
    const sortedCookies = useMemo(() => cookies.sort((c1, c2) => c1.name.replaceAll('_', '').localeCompare(c2.name.replaceAll('_', ''))), [cookies])
    const transition = useTransition(sortedCookies, {
        trail: 400 / cookies.length,
        key: c => c.name,
        from: {opacity: 0, transform: 'scale(0)'},
        enter: {opacity: 1, transform: 'scale(1)'},
        leave: {opacity: 0, transform: 'scale(0)'}
    })

    return <Dialog open={props.show} onClose={props.onHide} aria-labelledby="form-dialog-title" maxWidth={false}>
        <CookiesDialogTitle disableTypography={true} id="form-dialog-title">Manage Cookies</CookiesDialogTitle>
        <DialogContent>
            <CookiesGrid>
                {transition((style, c) => <GridItem target={props.target} style={style} seed={c.name}
                                                    added={addedCookies.includes(c.name)}
                                                    cookie={c}/>)}
            </CookiesGrid>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onHide} borderless>
                Close
            </Button>
        </DialogActions>
    </Dialog>

}
