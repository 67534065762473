import ContentLoader from "react-content-loader";

export const NameSkeleton = () => {
    return <ContentLoader
        speed={2}
        width={200}
        height={25}
        viewBox="0 0 200 27"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect width="200" rx="5" ry="5" height="27"/>
    </ContentLoader>

}
