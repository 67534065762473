import styled from "styled-components";
import {useMemo} from "react";

const CookieSvg = (props: { width: number, height: number }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 169 30">
        <g>
            <path fill="#fff" d="M138.94 25.6h3.28v3.28h-3.28z"/>
            <path fill="#fff" d="M144.45 14.57h8.745v3h-5.26v3h4.36v3h-4.36v5.304h-3.485z"/>
            <path fill="#fff"
                  d="M161.438 25.92c-2.145 0-3.862-1.845-3.862-4.284a3.868 3.868 0 1 1 7.723 0c0 2.445-1.723 4.283-3.861 4.283zm-5.303-9.534a7.238 7.238 0 0 0-2.185 5.25 7.484 7.484 0 0 0 14.968 0 7.238 7.238 0 0 0-7.48-7.302 7.238 7.238 0 0 0-5.303 2.052z"/>
            <path fill="#fff"
                  d="M77.115 14.818a14.234 14.234 0 0 1-14.56 14.547 14.56 14.56 0 0 1-9.44-3.306c-.565-.477-1.09-1-1.57-1.564a14.54 14.54 0 0 1-3.523-9.677 8.336 8.336 0 0 0-1.513-4.864 7.302 7.302 0 0 0-5.987-3.102c-4.168 0-7.507 3.422-7.507 7.966 0 4.181 2.598 7.45 6.064 8.17.476.105.962.157 1.45.154a7.226 7.226 0 0 0 6.012-3.275 16.308 16.308 0 0 0 3.428 6.192 14.285 14.285 0 0 1-7.277 3.191c-.717.096-1.44.143-2.164.14a14.234 14.234 0 0 1-14.534-14.572c0-7.966 6.147-14.176 14.547-14.176a14.821 14.821 0 0 1 9.408 3.191c.575.47 1.113.985 1.609 1.538a14.043 14.043 0 0 1 3.51 9.44 8.936 8.936 0 0 0 1.481 5.05 7.226 7.226 0 0 0 6.007 3.28c4.162 0 7.506-3.567 7.506-8.297 0-3.99-2.553-7.11-6.019-7.82a7.577 7.577 0 0 0-1.487-.172 7.302 7.302 0 0 0-5.981 3.102 15.957 15.957 0 0 0-3.415-6.12A14.521 14.521 0 0 1 60.82.73a15.16 15.16 0 0 1 1.736-.09c8.413 0 14.56 6.211 14.56 14.177z"/>
            <path fill="#fff"
                  d="M95.634 14.48v-.077l7.5-13.283h-7.296l-5.744 10.717h-2.796V1.12H80.5v27.766h6.791v-11.26h2.796l5.873 11.26h7.614z"/>
            <path fill="#fff" d="M106.39 1.12v27.766h6.798V1.12z"/>
            <path fill="#fff"
                  d="M22.168 19.686s-3.191 3.395-7.353 3.395c-5.106 0-7.813-4.212-7.813-8.297 0-3.996 2.521-7.896 7.813-7.896 3.83 0 6.932 2.821 6.932 2.821l3.147-5.113S21.35.69 14.509.69C6.185.69 0 6.875 0 14.924c0 8.196 5.84 14.355 14.509 14.355 4.196.06 8.23-1.619 11.144-4.64z"/>
            <path fill="#fff"
                  d="M125.308 23.058v-5.24h8.464v-5.821h-8.464V6.94h10.596V1.12H118.51v27.766h17.943v-5.828z"/>
        </g>
    </svg>)

const LogoContainer = styled.div<{ size?: { width: number; height: number } }>`
width:${props => props.size?.width}px;
height:${props => props.size?.height}px;
`
export const CookieFoLogo = (props: { size?: 'medium' | 'large' }) => {
    const dimensions = useMemo(() => props.size === 'large' ? {width: 239, height: 42} : {
        width: 169,
        height: 30
    }, [props.size])
    return <LogoContainer size={dimensions}><CookieSvg width={dimensions.width}
                                                       height={dimensions.height}/></LogoContainer>
}
