import styled, {css, useTheme} from "styled-components";
import {animated, config, useSpring} from "react-spring";
import React, {PropsWithChildren, useCallback} from "react";

const ToggleButtonRoot = animated(styled.button<{ active?: boolean; }>`
  display: block;
  position: relative;
  margin-bottom: 12px;
  font-size: 18px;
  padding: 4px 15px 3px;
  cursor: pointer;
  background-color: #EBF2F5;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`)

const ToggleButtonInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export type ToggleButtonProps = PropsWithChildren<{
    checked?: boolean;
    onToggle?: () => void;
    disabled?: boolean;
}>;

const ToggleButtonComponent = (props: ToggleButtonProps) => {
    // @ts-ignore
    const theme: { colorAccent: string } = useTheme()
    const toggleSpring = useSpring({
        backgroundColor: props.checked ? theme.colorAccent : '#EBF2F5',
        color: props.checked ? '#ffffff' : '#5E5E5E',
        config: config.stiff
    })
    const toggle = useCallback(() => {
        if (props.onToggle && !props.disabled) {
            props.onToggle();
        }
    }, [props.onToggle, props.disabled]);

    return (
        <ToggleButtonRoot active={props.checked} style={toggleSpring} onClick={toggle}>
            {props.children}
            <ToggleButtonInput
                type="checkbox"
                checked={props.checked}
                hidden
                readOnly
                onClick={toggle}
            />
        </ToggleButtonRoot>
    );
};
export const ToggleButton = React.memo(ToggleButtonComponent);
