import {Fragment, PropsWithChildren, useCallback, useState} from "react";
import {useTargetCookies} from "../../api/api";
import {useListTransition} from "../../globals";
import {Panel} from "./styles";
import {List, ListItem} from "../../components/list";
import {Button} from "../../components/Button";
import {ManageCookiesModal} from "./ManageCookiesModal";
import styled from "styled-components";
import {darken, easeInOut, transparentize} from "polished";
import {animated, useTransition} from "react-spring";
import {Http} from "../../api/client";
import {BASE_URL} from "../../Constants";

const CookieEntry = animated(styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-gap: 2px 12px;
  padding: 12px 24px;
  grid-template-areas: "namelabel providerlabel categorylabel actions"
                       "name      provider      category actions";
  border-bottom: 1px solid ${props => props.theme.colorDivider};

  &:last-of-type {
    border-bottom: none;
  }
`)
const Label = styled.div<{ for: string }>`
  color: ${transparentize(.3)('#282c34')};
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  grid-area: ${props => props.for}label;
`
const Value = styled.div<{ id: string }>`
  grid-area: ${props => props.id};
  color: #282c34;
  font-size: 15px;
`
const Action = styled.div`
  color: #f44336;
  transition: color 100ms ${easeInOut('quad')};
  display: block;
  padding: 12px 4px 12px 12px;
  cursor: pointer;

  &:hover {
    color: ${darken(.2)('#f44336')};
  }
`
const Actions = styled.div`

  grid-area: actions;
  display: flex;
  align-items: center;
`
export type CookiesPanelProps = PropsWithChildren<{ target: string }>
export const CookiesPanel = (props: CookiesPanelProps) => {
    const [open, setOpen] = useState(false)
    const [removed, setRemoved] = useState<any[]>([])
    const removeCookie = useCallback(async (cookie) => {
        const response = await Http.delete(BASE_URL + '/v2/targets/' + props.target + '/cookies/' + cookie.name)
        if (response.status >= 200 && response.status < 300) {
            setRemoved([...removed, cookie])
        }
    }, [props.target])
    const [cookies, loading] = useTargetCookies(props.target, [props.target, open, removed])
    const transition = useTransition(cookies, {
            key: (key) => key.name,
            from: {opacity: 0, translateX: -20},
            initial: {opacity: 0, translateX: -20},
            leave: {opacity: 0, translateX: -20, maxHeight:0},
            enter: {opacity: 1, translateX: 0},
            trail: (200 / cookies.length),
        }
    )
    return <Fragment>
        <ManageCookiesModal show={open} onHide={() => setOpen(false)} targetCookies={cookies} target={props.target}/>
        <Panel title="Cookies"
        description="Set the cookies that are shown on the consent banner"
               collapse={true}
               actions={<Button onClick={() => setOpen(true)}>Add Cookies</Button>}>
            <List>
                {transition((style, item) => item && (
                    <CookieEntry style={style}>
                        <Actions>
                            <Action onClick={() => removeCookie(item)}>
                                <svg height="18px" width="18px" version="1.1" viewBox="0 0 24 24"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor"
                                          d="M22.245,4.015c0.313,0.313,0.313,0.826,0,1.139l-6.276,6.27c-0.313,0.312-0.313,0.826,0,1.14l6.273,6.272  c0.313,0.313,0.313,0.826,0,1.14l-2.285,2.277c-0.314,0.312-0.828,0.312-1.142,0l-6.271-6.271c-0.313-0.313-0.828-0.313-1.141,0  l-6.276,6.267c-0.313,0.313-0.828,0.313-1.141,0l-2.282-2.28c-0.313-0.313-0.313-0.826,0-1.14l6.278-6.269  c0.313-0.312,0.313-0.826,0-1.14L1.709,5.147c-0.314-0.313-0.314-0.827,0-1.14l2.284-2.278C4.308,1.417,4.821,1.417,5.135,1.73  L11.405,8c0.314,0.314,0.828,0.314,1.141,0.001l6.276-6.267c0.312-0.312,0.826-0.312,1.141,0L22.245,4.015z"/>
                                </svg>
                            </Action>
                        </Actions>
                        <Label for="name">Cookie Name</Label>
                        <Label for="provider">Provider</Label>
                        <Label for="category">Categories</Label>
                        <Value id="name">{item.name}</Value>
                        <Value id="provider">{item.provider}</Value>
                        <Value id="category">{item.categories.join(", ")}</Value>
                    </CookieEntry>
                ))}
            </List>
        </Panel>
    </Fragment>
}
