import {config, useTransition} from "react-spring";
import React, {useCallback, useState} from "react";
import ContentLoader from "react-content-loader";

export const useListTransition = <T>(items: T[], keySelector?: (item: T) => string) => useTransition(items, {
        key: keySelector,
        from: {opacity: 0, translateX: -20},
        initial: {opacity: 0, translateX: -20},
        leave: {opacity: 0, translateX: -20},
        enter: {opacity: 1, translateX: 0},
        trail: (200 / items.length),
    }
)


export function useDialogControl() {
    const [show, set] = useState(false)
    const open = useCallback(() => {
        set(true)
    }, [set])
    return {
        handle: {
            onHide: () => set(false),
            show,
        },
        open
    }
}


