import {createContext, useContext} from "react";
import {Color} from "./ColorPicker";

interface ColorPickerContextType {
    selectedColor: Color;
    isCustom: boolean;
    setColor: (color: Color) => void;
}

export const ColorPickerContext = createContext<ColorPickerContextType>({
    selectedColor: '#000000',
    isCustom: false,
    setColor: () => {
    }
})

export function useColorPickerContext() {
    return useContext<ColorPickerContextType>(ColorPickerContext)
}
