import styled from "styled-components";
import {DialogContentText, DialogTitle} from "@material-ui/core";

export const CookiesDialogTitle = styled(DialogTitle)`
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: ${props => props.theme.colorAccent};
  font-size: 24px;
`

export const DialogText = styled.p`
  font-family: "Questrial", "Helvetica Neue", sans-serif;
  margin-bottom:15px;
  color: ${props => props.theme.colorPrimary};
`
