import React, {Fragment, useCallback, useState} from "react";
import {Page} from "../../components/Page";
import {Customer, Target, useTargets} from "../../api/api";
import {Button} from "../../components/Button";
import {List, ListItem} from "src/components/list";
import {useDialogControl, useListTransition} from "../../globals";
import {TargetName} from "./styles";
import {useHistory, useRouteMatch} from "react-router-dom";
import {CreateTargetDialog} from "./CreateTargetDialog";

export const Targets = () => {
    const dialogControl = useDialogControl()
    const [added, setAdded] = useState<Target[]>([])
    const {targets, loading} = useTargets([added])
    const history = useHistory()
    const {url, path} = useRouteMatch()
    const handleItemClick = useCallback((id) => {
        history.push(url + '/' + id)
    }, [targets]);
    const transition = useListTransition(targets ?? [], (t) => t.name)
    return <Fragment><Page title="Targets" loading={loading}>
        <Button onClick={() => dialogControl.open()}>New Target</Button>
        <List>
            {transition((style, item) => (
                item && <ListItem key={item.name.toString()} style={style} onClick={() => handleItemClick(item.name)}>
                    <TargetName>{item.name.length > 18 ? item.name.substr(0, 15) + '...' :  item.name}</TargetName>
                    {item.displayName}
                </ListItem>)
            )}
        </List>
    </Page>
        <CreateTargetDialog show={dialogControl.handle.show} postCreate={(val) => setAdded([...added, val])}
                            onHide={dialogControl.handle.onHide}/>
    </Fragment>
}
