import {Button} from "../../components/Button";
import {Section} from "../../components/section";
import {ColorPicker} from "../../components/color-picker/ColorPicker";
import {LanguageWarning, Panel, Select, SplitPanel, SwitchLabel, ToggleButtons} from "./styles";
import {ToggleButton} from "../../components/Radio";
import {BannerType, Language, Target, useTarget} from "../../api/api";
import Switch from "../../components/switch";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useTransition} from "react-spring";
import {Http} from "../../api/client";
import {BASE_URL} from "../../Constants";
import {Snackbar} from "@material-ui/core";

export interface CustomizationPanelProps {
    target: string;
}

export type FormState = 'untouched' | 'dirty';
export const CustomizationPanel = (props: CustomizationPanelProps) => {
    const [formState, setFormState] = useState<FormState>('untouched')
    const [saved, setSaved] = useState(false)
    const [target, loading] = useTarget(props.target, [props.target])
    const [state, setState] = useState(target)
    const set = useCallback((params: (prev: Target | undefined) => Target) => {
        setFormState('dirty')
        setState(params)
    }, [state])
    useEffect(() => {
        setState(target)
        setFormState('untouched')
    }, [setState, target, setFormState])
    const themeTransition = useTransition(state, {
        key: (v) => v?.name ?? "0",
        from: {opacity: 0},
        enter: {opacity: 1}
    });
    const [saving, isSaving] = useState(false)

    // const saveChanges = useCallback(async () => {

    // }, [theme, setSaving, useToggle, dialogType, defaultLanguage, languages])

    const toggleLanguage = useCallback((lang) => {
        if (!state) return
        if (state.languages.includes(lang)) {
            set((prev) => ({...prev!, languages: prev!.languages.filter((it) => it !== lang)}))
        } else {
            set((prev) => ({...prev!, languages: [...prev!.languages, lang]}))
        }
    }, [state])

    const setUseToggle = useCallback((useToggle) => {
        if (!state) return;
        set((prev) => ({...prev!, useToggle}))
    }, [state])

    const setDefaultLanguage = useCallback((defaultLanguage) => {
        if (!state) return;
        set((prev) => ({...prev!, defaultLanguage}))
    }, [state])

    const setColorAccent = useCallback((colorAccent) => {
        if (!state) return;
        set((prev) => ({...prev!, theme: {...prev!.theme, colorAccent}}))
    }, [state])

    const setColorPrimary = useCallback((colorPrimary) => {
        if (!state) return;
        set((prev) => ({...prev!, theme: {...prev!.theme, colorPrimary}}))
    }, [state])


    const setDialogType = useCallback((bannerType) => {
        if (!state) return;
        set((prev) => ({...prev!, bannerType}))
    }, [state])


    const saveChanges = useCallback(async () => {
        isSaving(true)
        setSaved(false)
        try {
            const response = await Http.put<Target>(BASE_URL + '/v2/targets/' + props.target, state)
            setState(response.data)
            setFormState('untouched')
            setSaved(true)
        } catch (e) {
        } finally {
            isSaving(false)
        }
    }, [state, props.target])
    return <Panel title="Customisation"
                  description="Customize the visual appearance of the consent banner"
                  actions={<Button disabled={saving || formState !== 'dirty'} onClick={() => saveChanges()}>Save
                      Changes</Button>}>
        {themeTransition((style, value) => value &&
            <Fragment>
                <Section style={style} title="Theme">
                    Primary Color
                    <ColorPicker
                        value={value.theme.colorPrimary}
                        onChange={(color) => setColorPrimary(color)}
                        colors={['#216900', '#008857', '#007283', '#003E83', '#890087', '#95002E', '#B01201', '#B05200', '#C89700', '#363636']}/>
                    Accent Color
                    <ColorPicker
                        value={value.theme.colorAccent}
                        onChange={(color) => setColorAccent(color)}
                        colors={['#019900', '#32CC94', '#31C3D9', '#4983C4', '#A25AA1', '#DD4C79', '#EE5545', '#F28729', '#FFCF00', '#767676']}/>
                </Section>
                <Section title="Banner Configuration">
                    <SplitPanel>
                        <SwitchLabel>
                            Available Languages
                            <ToggleButtons>
                                <ToggleButton checked={value.languages.includes('fo') ?? false}
                                              onToggle={() => toggleLanguage('fo')}>Føroyskt</ToggleButton>
                                {/*<ToggleButton checked={languages?.includes('da') ?? false}*/}
                                {/*              onToggle={() => toggleLanguage('da')}>Danskt</ToggleButton>*/}
                                <ToggleButton checked={value.languages.includes('en') ?? false}
                                              onToggle={() => toggleLanguage('en')}>English</ToggleButton>
                            </ToggleButtons>
                        </SwitchLabel>
                        <SwitchLabel>
                            <div>Default Language {!(value.languages?.includes(value.defaultLanguage!)) &&
                            <LanguageWarning>Default language
                                is not in list of available languages</LanguageWarning>}</div>
                            <Select value={value.defaultLanguage}
                                    onChange={(event) => setDefaultLanguage(event.target.value as Language)}>
                                <option value="en">English</option>
                                <option value="da">Dansk</option>
                                <option value="fo">Føroyskt</option>
                            </Select>
                        </SwitchLabel>
                        <SwitchLabel>
                            Banner Layout
                            <Select value={value.bannerType}
                                    onChange={(event) => setDialogType(event.target.value as BannerType)}>
                                <option value="Docked">Docked</option>
                                <option value="Floating">Floating</option>
                                <option value="FullWidth">Full Width</option>
                            </Select>
                        </SwitchLabel>
                        <SwitchLabel>
                            Use Toggle Widget
                            <Switch checked={value.useToggle} onToggle={() => setUseToggle(!value.useToggle)}/>
                        </SwitchLabel>
                    </SplitPanel>
                </Section>
                <Snackbar message="Your changes have been saved" anchorOrigin={{vertical: 'top', horizontal: 'center',}}
                          onClose={() => setSaved(false)} open={saved} autoHideDuration={3000}></Snackbar>
            </Fragment>)}
    </Panel>
}
