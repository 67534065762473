import axios from "axios";
import {getAccessToken, useAuthTokenInterceptor} from "axios-jwt";

const client = axios.create()

export const authResponseToAuthTokens = (token:string) => (token);

// Not really a hook.
// eslint-disable-next-line react-hooks/rules-of-hooks
useAuthTokenInterceptor(client, {requestRefresh: () => Promise.resolve(getAccessToken()!)})

export const Http = client;
