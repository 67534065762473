import React, {PropsWithChildren, useCallback, useState} from "react";
import {Link, NavLink, Redirect} from "react-router-dom";
import styled from "styled-components";
import {CookieFoLogo} from "./CookieFoLogo";
import {transparentize} from "polished";
import {Button} from "./Button";
import {useAuthContext} from "../auth/auth";
import {clearAuthTokens} from "axios-jwt";

const SidenavHeader = styled.div`
//background: url("login_backdrop.svg") no-repeat;
background-size: cover;
display:flex;
align-items: center;
justify-content: center;
height:120px;
`

const SideNav = styled.nav`
background-color: #021e3f;
height:100%;
box-sizing: border-box;
display:flex;
flex-direction: column;
padding:12px;
`
const SidenavItem = styled(NavLink)`
border-bottom: 1px solid #1295d4;
color: rgba(255,255,255,.97);
padding:12px;
transition: background-color 150ms ease-in-out;
cursor: pointer;
&:first-of-type{
border-radius: 5px 5px 0 0;
}
&:last-of-type{
border-radius: 0 0 5px 5px;
}
&:not(.active):hover{
background-color: #1295d4;
}
&:last-of-type{
border-bottom: none;
}
&.active{
background-color: ${transparentize(.13)('#1295d4')};
}
`
const Spacer = styled.div`
flex: 1 1 auto;
`
const SignOutButton = (props: PropsWithChildren<any>) => {
    const [commit, set] = useState(false)
    const {logout} = useAuthContext()
    const onClick = useCallback(() => {
        clearAuthTokens()
        logout()
        set(true)
    }, [set])
    return commit ? <Redirect to="/login"/> : <Button style={{flex: '0 0 auto'}} onClick={onClick}>{props.children}</Button>
}
export const SideMenu = () => {

    return <SideNav>
        <SidenavHeader>
            <CookieFoLogo/>
        </SidenavHeader>
        {/*<SidenavItem to="/manage/customers">Customers</SidenavItem>*/}
        <SidenavItem to="/manage/targets">Targets</SidenavItem>
        <SidenavItem to="/manage/cookies">Cookies</SidenavItem>
        <SidenavItem to="/manage/accounts">Accounts</SidenavItem>
        <Spacer />
        <SignOutButton>Sign Out</SignOutButton>

    </SideNav>
}
