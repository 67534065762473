import React, {PropsWithChildren} from "react";
import {SectionContent, SectionHeader, SectionWrapper} from "./styles";

export const Section = (props: PropsWithChildren<{ title: string }> & { style?: any }) => {
    return <SectionWrapper style={props.style}>
        <SectionHeader>
            {props.title}
        </SectionHeader>
        <SectionContent>
            {props.children}
        </SectionContent>
    </SectionWrapper>
}
