import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Page} from "../../components/Page";
import {useAuthContext} from "../../auth/auth";
import {Customer, useCustomers} from "../../api/api";
import styled from "styled-components";
import {animated, useTransition} from 'react-spring';
import {transparentize} from "polished";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Button} from "../../components/Button";
import {CreateCustomerModal} from "./CreateCustomerModal";
import {ListItem, List} from "src/components/list";
import {CustomerId} from "./styles";
import {useDialogControl} from "../../globals";


const CustomerRow = animated(styled.li`
  &:hover {
    background-color: ${props => transparentize(.83)(props.theme.colorAccent)};
  }

  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    border-top: 1px solid ${props => props.theme.colorAccent};
  }

  &:first-of-type:after {
    content: unset;
  }

  border-radius: 5px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
  display: flex;

  &:first-of-type {
    border-top: none;
  }

  align-items: center;
  padding: 15px;
`)

const CustomerList = styled.ul`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`

export const Customers = () => {
    const {token} = useAuthContext()
    const [added, setAdded] = useState<Customer[]>([])
    const {customers, loading} = useCustomers([added])
    const dialogControl = useDialogControl();
    useEffect(() => {
    }, [token])
    const transition = useTransition(customers, {
        from: {opacity: 0, translateX: -20},
        initial: {opacity: 0, translateX: -20},
        enter: {opacity: 1, translateX: 0},
        trail: 50
    })
    const history = useHistory()
    const {url, path} = useRouteMatch()
    const handleItemClick = useCallback((id) => {
        history.push(url + '/' + id)
    }, [customers]);
    return <Fragment><Page title="Customers" loading={loading}>
        <Button onClick={() => dialogControl.open()}>New Customer</Button>
        <List>
            {transition((style, item) => (
                item && <ListItem key={item.id.toString()} style={style} onClick={() => handleItemClick(item.id)}>
                    <CustomerId>{item.id}</CustomerId>
                    {item.name}
                </ListItem>)
            )}
        </List>
    </Page>
        <CreateCustomerModal {...dialogControl.handle} postCreate={(c) => setAdded([...added, c])}/>
    </Fragment>
}
