import {API, CookieValue, Target} from "../../api/api";
import React, {useCallback, useState} from "react";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {CookiesDialogTitle, DialogText} from "../../components/QookiesDialogTitle";
import {Input, Select} from "../../components/Input";
import {Button} from "../../components/Button";

const initialState: CookieValue = {
    name: '',
    provider: '',
    categories: ['STRICT'],
    description: {
        fo: null,
        en: null,
        da: null
    },
    label: ''
}
export const CreateCookieModal = (props: { show: boolean, postCreate: (key: Target) => void, onHide: () => void }) => {
    const [{name, provider, categories, description, label}, set] = useState(initialState)
    const [isSending, setIsSending] = useState(false)

    const sendRequest = useCallback(async () => {
        if (isSending) return;
        setIsSending(true)
        const response = await API.createCookie({name, provider, categories, description, label})
        setIsSending(false)
        props.onHide();
        props.postCreate(response.data);
        set(initialState)
    }, [isSending, name, provider, categories, description, label, set])

    return <Dialog open={props.show} onClose={props.onHide} aria-labelledby="form-dialog-title">
        <CookiesDialogTitle disableTypography={true} id="form-dialog-title">New Customer</CookiesDialogTitle>
        <DialogContent>
            <DialogText>
                Provide a display-friendly customer name and a contact email address.
            </DialogText>
            <Input type="text" placeholder="Cookie Name"
                   onChange={(event) => set((s) => ({...s, name: event.target.value}))}
                   value={name}/>
            <Input type="text" placeholder="Provider"
                   onChange={(event) => set((s) => ({...s, provider: event.target.value}))}
                   value={provider}/>
            <Input type="text" placeholder="Label (optional)"
                   onChange={(event) => set((s) => ({...s, label: event.target.value}))}
                   value={label ?? ''}/>
            <Select value={categories[0]} onChange={event => set((s) => ({...s, categories: [event.target.value]}))}>
                <option value="STRICT">Necessary</option>
                <option value="PREFERENCES">Preferences</option>
                <option value="STATISTICS">Statistics</option>
                <option value="MARKETING">Marketing</option>
            </Select>
            <Input type="text" placeholder="Description (FO)"
                   onChange={(event) => set((s) => ({...s, description: {...s.description, fo: event.target.value}}))}
                   value={description?.fo ?? ''}/>
            <Input type="text" placeholder="Description (EN)"
                   onChange={(event) => set((s) => ({...s, description: {...s.description, en: event.target.value}}))}
                   value={description?.en ?? ''}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onHide} borderless>
                Cancel
            </Button>
            <Button onClick={sendRequest}>
                Create
            </Button>
        </DialogActions>
    </Dialog>

}
