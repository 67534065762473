import styled, {css} from "styled-components";

export const StyledColorPicker = styled.ul`
  list-style: none;
  margin: 0;
  padding: 12px 0;
  display: flex;

`
export const StyledColorItem = styled.li<{ color: string, active?: boolean }>`
  list-style-type: none;
  background-color: ${props => props.color};
  border-radius: 100%;
  height: 36px;
  margin: 0 7px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  width: 36px;
  position: relative;
  z-index: 4;
  cursor: pointer;

  &:before {
    z-index: -1;
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
    opacity: 0;
    transform: scale(0.9);
    content: '';
    width: 46px;
    height: 46px;
    top: -5px;
    left: -5px;
    border-radius: 100%;
    position: absolute;
    border: 2px solid ${props => props.theme.colorAccent};
  }

  ${props => props.active && css`
    &:before {
      opacity: 1;
      transform: scale(1.0);
    }
  `}

`

export const ColorPickerWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledColorPickerInput = styled.input<{ color: string, valid: boolean }>`
  &:after {
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${props => props.color};
  }

  margin-right: 14px;
  margin-left: 14px;
  font-size: 18px;
  border: 1px solid ${props => props.valid ? '#D3DFE4' : '#ff0000'};
  text-align: center;
  border-radius: 36px;
  color: #5E5E5E;
  background-color: #EBF2F5;
  padding: 4px 15px 3px;
  appearance: none;
  outline: none;
`
