import {AdminAccount, API, Customer, Role} from "../../api/api";
import React, {useCallback, useState} from "react";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {CookiesDialogTitle, DialogText} from "../../components/QookiesDialogTitle";
import {Input, Select} from "../../components/Input";
import {Button} from "../../components/Button";

const initialState: { name: string, username: string, password: string, role: Role } = {
    name: '',
    username: '',
    password: '',
    role: 'ADMIN'
}

export const CreateAccountModal = (props: { show: boolean, postCreate: (key: AdminAccount) => void, onHide: () => void }) => {
    const [{name, password, username, role}, set] = useState(initialState)
    const [isSending, setIsSending] = useState(false)

    const sendRequest = useCallback(async () => {
        if (isSending) return;
        setIsSending(true)
        const response = await API.createAccount({name, role, password, username})
        setIsSending(false)
        props.onHide();
        props.postCreate(response.data);
        set({...initialState})
    }, [isSending, name, role, password, username])

    return <Dialog open={props.show} onClose={props.onHide} aria-labelledby="form-dialog-title">
        <CookiesDialogTitle disableTypography={true} id="form-dialog-title">Subscribe</CookiesDialogTitle>
        <DialogContent>
            <DialogText>
                Provide a display-friendly customer name and a contact email address.
            </DialogText>
            <Input type="text" placeholder="Administrator Name"
                   onChange={(event) => set((prev) => ({...prev, name: event.target.value}))}
                   value={name}/>
            <Input type="text" placeholder="Username"
                   onChange={(event) => set((prev) => ({...prev, username: event.target.value}))}
                   value={username}/>
            <Select value={role} onChange={(event) => set((prev) => ({...prev, role: event.target.value as Role}))}>
                <option value="ADMIN">Administrator</option>
                <option value="SUPERADMIN">Superadmin</option>
            </Select>
            <Input type="password" placeholder="Password"
                   onChange={(event) => set((prev) => ({...prev, password: event.target.value}))}
                   value={password}/>
            {/*<Input type="email" placeholder="Role" onChange={(event) => set((prev) => ({...prev, name: event.target.value}))}*/}
            {/*       value={email}/>*/}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onHide} borderless>
                Cancel
            </Button>
            <Button onClick={sendRequest}>
                Create
            </Button>
        </DialogActions>
    </Dialog>

}
