import React, {useEffect} from "react";

import styled, {keyframes} from "styled-components";
import {transparentize} from "polished";
import {animated, useSpring} from "react-spring";


const long = keyframes`
  0% {left: -35%;right: 100%}
  60% {left: 100%;right: -90%}
  100% {left: 100%;right: -90%}
`;

const short = keyframes`
  0% {left: -200%;right: 100%}
  60% {left: 107%;right: -8%}
  100% {left: 107%;right: -8%}
`;

export interface BarLoaderProps {
    loading: boolean;
    height: number | string;
    width: number | string;
}

const dimension = (pxOrPercentage: string | number) => {
    if (typeof pxOrPercentage === 'number') {
        return pxOrPercentage + 'px'
    } else {
        return pxOrPercentage
    }
}
const LongLoaderSpan = styled.span<Omit<BarLoaderProps, 'loading'>>`
position: absolute;
      height: ${props => dimension(props.height)};
      overflow: hidden;
      background-color: #d9029e;
      background-clip: padding-box;
      display: block;
      border-radius: 2px;
      will-change: left, right;
      animation-fill-mode: forwards;
      animation: ${long} 3s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;

`

const ShortLoaderSpan = styled.span<Omit<BarLoaderProps, 'loading'>>`
 position: absolute;
      height: ${props => dimension(props.height)};
      overflow: hidden;
      background-color: #d9029e;
      background-clip: padding-box;
      display: block;
      border-radius: 2px;
      will-change: left, right;
      animation-fill-mode: forwards;
      animation: ${short} 3s 1.6s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
`

const Wrapper = animated(styled.span<Omit<BarLoaderProps, 'loading'>>`
      display:block;
      position: relative;
      width: ${props => dimension(props.width)};
      height: ${props => dimension(props.height)};
      overflow: hidden;
      background-color: ${transparentize(.7)('#d9029e')};
      background-clip: padding-box;
`)
export const BarLoader = (props: BarLoaderProps) => {
    const heightSpring = useSpring({opacity: props.loading ? 1 : 0})
    return <Wrapper style={heightSpring} width={props.width}
                    height={props.height}><LongLoaderSpan width={props.width}
                                                          height={props.height}/><ShortLoaderSpan width={props.width}
                                                                                                  height={props.height}/></Wrapper>
}


