import {PropsWithChildren, useCallback, useState} from "react";
import {Panel} from "./styles";
import {Button} from "../../components/Button";
import {ApiKey, useTargetApiKeys} from "../../api/api";
import {useListTransition} from "../../globals";
import {List, ListItem} from "../../components/list";
import {Http} from "../../api/client";
import {BASE_URL} from "../../Constants";

export type ApiKeysPanelProps = PropsWithChildren<{ target: string }>
export const ApiKeysPanel = (props: ApiKeysPanelProps) => {
    const [creating, setCreating] = useState(false)
    const [added, setAdded] = useState<ApiKey[]>([])
    const [apiKeys, loading] = useTargetApiKeys(props.target, [props.target, added])
    const createApiKey = useCallback(async () => {
        const response = await Http.post(BASE_URL + "/v2/targets/" + props.target + '/apikeys', {})
        setAdded((a) => [...a, response.data])
    }, [props.target])
    const transition = useListTransition(apiKeys, (key) => key.apiKey)
    return <Panel title="API Keys"
                  description="Create API keys for consent banner access"
                  collapse={true}
                  actions={<Button disabled={creating} onClick={() => createApiKey()}>New API Key</Button>}>
        <List>
            {transition((style, item) => item && <ListItem style={style}>{item.apiKey}</ListItem>)}
        </List>
    </Panel>
}
