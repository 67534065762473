import {Page} from "../../components/Page";
import React, {Fragment, PropsWithChildren, useCallback, useMemo, useState} from "react";
import {Link, useParams} from "react-router-dom";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import {API, ApiKey, useApiKeys, useCustomer} from "../../api/api";
import {animated, config, useTransition} from "react-spring";
import {HintText} from "../../components/HintText";
import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {CookiesDialogTitle, DialogText} from "../../components/QookiesDialogTitle";
import axios from "axios";
import {useAuthContext} from "../../auth/auth";
import {onHidden} from "web-vitals/dist/lib/onHidden";
import {ModalActions, ModalContent, ModalDialog, ModalTitle} from "../../components/Modal";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useDialogControl} from "../../globals";
import {CreateApiKeyModal} from "./CreateAPIKeyModal";
import { BreadCrumb } from "src/components/breadcrumbs";
import { NameSkeleton } from "src/components/skeleton/NameSkeleton";


const CustomerOverview = animated(styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`)
const OverviewCell = styled.div<{ title?: string }>`
  display: flex;
  flex-direction: column;
  font-weight: 400;
`
const Legend = styled.div`
  color: ${props => props.theme.colorAccent};
`

const SubTitle = styled.h3`
  display: flex;
  font-family: 'Inter', sans-serif;
  color: ${props => props.theme.colorAccent};
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 24px;
`

const ApiKeysTableRoot = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  grid-column: auto / span 2;
`
const ApiKeysTableRow = animated(styled.div`
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.colorPrimary};
`);
const ApiKeysTableHeader = styled(ApiKeysTableRow)`
  border-top: none;
  font-family: 'Inter', sans-serif;
  cursor: default;
`;
const Cell = styled.div`
  font-weight: 400;
  flex: 0 0 50%;
`;
const ApiKeysTable = (props: { loading: boolean, apikeys: ApiKey[] | undefined }) => {
    const transition = useTransition(props.apikeys ?? [], {
        from: {opacity: 0, translateX: -20},
        enter: {opacity: 1, translateX: 0},
        trail: 50
    });
    return <ApiKeysTableRoot>
        <ApiKeysTableHeader>
            <Cell>Target</Cell>
            <Cell>API Key</Cell>
        </ApiKeysTableHeader>
        {transition((style, apikey) => apikey && <ApiKeysTableRow style={style}>
            <Cell>{apikey.target}</Cell>
            <Cell>{apikey.apiKey}</Cell>
        </ApiKeysTableRow>)}
        {(!props.loading && (props.apikeys ?? []).length === 0) &&
        <HintText>There are no API keys for this customer.</HintText>}
    </ApiKeysTableRoot>
}

export const CustomerDetails = () => {
    const {customerId} = useParams<any>()
    const {customer, loading} = useCustomer(customerId)
    const transition = useTransition(customer, {
        from: {opacity: 0},
        enter: {opacity: 1}
    })
    const dialogControl = useDialogControl();
    const [added, setAdded] = useState<ApiKey[]>([])
    const {apikeys, loading: loadingApiKeys} = useApiKeys(customerId, [added])

    return <Fragment><Page
        title={<Fragment><BreadCrumb to="/manage/customers">Customers</BreadCrumb>&nbsp;/ {customer ? customer.name :
            <NameSkeleton/>}</Fragment>}
        loading={loading || loadingApiKeys}>
        {transition((style, customer) => customer && <CustomerOverview style={style}>
            <OverviewCell>
                <Legend>Name</Legend>
                {customer.name}
            </OverviewCell>
            <OverviewCell>
                <Legend>Contact Email</Legend>
                {customer.email}
            </OverviewCell>
            <SubTitle>API Keys</SubTitle>
            <div style={{gridColumn: 'auto / span 2'}}>
                <Button onClick={() => dialogControl.open()}>New API Key</Button>
                <ApiKeysTable apikeys={apikeys} loading={loadingApiKeys}/>
            </div>
        </CustomerOverview>)}
    </Page>
        <CreateApiKeyModal customer={customerId} postCreate={(item) => setAdded([...added, item])}
                           {...dialogControl.handle}/>
    </Fragment>
}
