import React, {Fragment, useState} from "react";
import {Page} from "../../components/Page";
import {useParams} from "react-router-dom";
import {useTarget} from "../../api/api";
import {BreadCrumb} from "src/components/breadcrumbs";
import {NameSkeleton} from "../../components/skeleton/NameSkeleton";
import {useTransition} from "react-spring";
import {Label, Panel, SplitPanel, Value} from "./styles";
import {Snackbar} from "@material-ui/core";
import {ApiKeysPanel} from "./ApiKeysPanel";
import {CookiesPanel} from "./CookiesPanel";
import {CustomizationPanel} from "./CustomizationPanel";


export const TargetDetails = () => {
    const {targetId} = useParams<any>()
    const [target,loading] = useTarget(targetId, [targetId])
    const [saveError, setSaveError] = useState<false | string>(false)
    const transition = useTransition(target, {
        from: {opacity: 0},
        enter: {opacity: 1}
    })


    return <Fragment><Page
        title={<Fragment><BreadCrumb to="/manage/targets">Targets</BreadCrumb>&nbsp;/ {target ? target.displayName :
            <NameSkeleton/>}</Fragment>}
        loading={loading}>
        {transition((style, item) => item && (<div>
            <Panel title="Overview">
                <SplitPanel>
                    <div>
                        <Label>Target Identifier</Label>
                        <Value>{item.name}</Value>
                    </div>
                    <div>
                        <Label>Display Name</Label>
                        <Value>{item.displayName}</Value>
                    </div>
                </SplitPanel>
            </Panel>
            <CustomizationPanel target={targetId!}/>
            <CookiesPanel target={targetId!}/>
            <ApiKeysPanel target={targetId!}/>

        </div>))}
    </Page>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={() => setSaveError(false)}
            open={saveError !== false}
            autoHideDuration={5000}
            message={saveError}
        />
    </Fragment>
}
