import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {Column, Grid} from "../components/Grid";
import {CookieFoLogo} from "../components/CookieFoLogo";
import {Button} from "../components/Button";
import {Redirect, useHistory} from 'react-router-dom'
import axios from "axios";
import {BarLoader} from "../components/BarLoader";
import {isLoggedIn, setAuthTokens, useAuthTokenInterceptor} from "axios-jwt";
import {IconButton, Snackbar} from "@material-ui/core";
import {BASE_URL} from "../Constants";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginInput = styled.input`
  border-radius: 5px;
  border: 1px solid #1295d4;
  background-color: #ebf2f5;
  color: #021e3f;
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  padding: 20px 30px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  outline: none;
  width: 100%;
  min-width: 474px;
  margin-bottom: 25px;
`;

const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const LoginBanner = styled(Column)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 450px;
  background-image: url("/login_backdrop.svg");
  height: 100%;
`;

const LoginTitle = styled.h2`
  margin-top: 24px;
  color: rgba(255, 255, 255, 0.97);
  font-size: 24px;
`;
const LoaderContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export default function Login() {
    const [state, set] = useState({username: "", password: ""});
    const [loggingIn, setLoggingIn] = useState(false);
    const [credentialError, setCredentialError] = useState(false);
    const onPasswordChange = useCallback(
        (event) => {
            set({password: event.target.value, username: state.username});
        },
        [set, state]
    );

    const onUsernameChange = useCallback(
        (event) => {
            set({password: state.password, username: event.target.value});
        },
        [set, state]
    );

    const onSubmit = useCallback(
        async (event) => {
            setCredentialError(false);
            event.preventDefault();
            setLoggingIn(true);
            const tokenResponse = await axios.post<string>(BASE_URL + "/v2/authenticate", state, {validateStatus: (status) => status <= 500});
            if (tokenResponse.status === 200) {
                const auth = {accessToken: tokenResponse.data, refreshToken: tokenResponse.data}
                setAuthTokens(auth)
            } else {
                if (tokenResponse.status === 401) {
                    setCredentialError(true);
                } else {
                    console.error('An error occurred logging in:', tokenResponse)
                }
            }
            setLoggingIn(false);
        },
        [state, setLoggingIn]
    );
    return (isLoggedIn() ? <Redirect to="/"/> :
            <Wrapper className="bg-blue-50">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={() => setCredentialError(false)}
                    open={credentialError}
                    autoHideDuration={5000}
                    message="The provided username or password does not match our records."
                />
                <Grid className="bg-white rounded-lg shadow-lg" collapse>
                    <LoginBanner span={5} justify="center" align="center">
                        <CookieFoLogo/>
                        <LoginTitle>API Console Login</LoginTitle>
                    </LoginBanner>
                    <Column className="p-8" span={7} justify="center">
                        <LoginForm onSubmit={onSubmit}>
                            <LoginInput
                                value={state.username}
                                onChange={onUsernameChange}
                                placeholder="Username"
                                type="text"
                                autoComplete={"off"}
                            />
                            <LoginInput
                                value={state.password}
                                onChange={onPasswordChange}
                                placeholder="Password"
                                type="password"
                                autoComplete={"off"}
                            />
                            <Button disabled={loggingIn} type="submit">Login</Button>
                        </LoginForm>
                        <LoaderContainer><BarLoader loading={loggingIn} height={4} width="100%"/></LoaderContainer>
                    </Column>
                </Grid>
            </Wrapper>
    );
}
