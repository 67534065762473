import React from 'react';
import './App.css';
import Login from "./pages/Login";
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {useAuth} from "./auth/auth";
import {createMuiTheme, ThemeProvider as MUIThemeProvider} from "@material-ui/core";
import {GuardedRoute} from "./auth/GuardedRoute";
import {Main} from "./pages/Main";
import styled, {ThemeProvider} from "styled-components";

const AppRoot = styled.div`
height:100vh;
width:100vw;
`
const muiTheme =createMuiTheme({
    overrides:{
        MuiSnackbar:{
            root:{
                backgroundColor:'#021e3f'
            }
        },
        MuiSnackbarContent:{
            root:{
                backgroundColor:'#021e3f'
            }
        }
    },
    typography: {
        fontFamily: [
            'Questrial',
            'sans-serif',
        ].join(','),
    },
});

function App() {
    const appTheme = {
        colorAccent: '#d9029e',
        colorPrimary: '#021e3f',
        colorSeparator: '#1295d4',
        colorDivider: 'rgba(40,44,52,0.3)'
    }
    const AuthContext = useAuth()
    return (
        <MUIThemeProvider theme={muiTheme}>
        <ThemeProvider theme={appTheme}>
        <AuthContext>
            <BrowserRouter>
                <AppRoot>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/manage"/>}/>
                        <Route path="/login" component={Login}/>
                        <GuardedRoute path="/manage" component={Main}/>
                    </Switch>
                </AppRoot>
            </BrowserRouter>
        </AuthContext>
        </ThemeProvider>
        </MUIThemeProvider>
    );
}

export default App;
