import React, {Fragment, useCallback, useMemo, useState} from "react";
import {ColorPickerContext, useColorPickerContext} from "./Context";
import {ColorPickerWrapper, StyledColorItem, StyledColorPicker, StyledColorPickerInput} from "./styles";

export type Color = string;

export interface ColorPickerProps {
    value: Color;
    onChange?: (color: Color) => void;
    colors: Color[];
}

type ColorItemProps = { color: Color }
const ColorItem = (props: ColorItemProps) => {
    const {setColor, selectedColor} = useColorPickerContext();
    return <StyledColorItem color={props.color} active={selectedColor === props.color}
                            onClick={() => setColor(props.color)}/>
}
const colorRegex = (/#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/);
const ColorPickerInput = () => {
    const {isCustom, setColor, selectedColor} = useColorPickerContext();
    const [customColor, set] = useState(() => selectedColor);
    const [isValid, setIsValid] = useState(true);
    const onColorChange = useCallback((color) => {
        if (colorRegex.test(color)) {
            setIsValid(true)
            setColor(color)
        } else {
            setIsValid(false)
            setColor('')
        }
        set(color)
    }, [setColor, set])
    return <Fragment>
        <StyledColorPickerInput value={isCustom ? customColor : selectedColor}
                                onChange={(event) => onColorChange(event.target.value)}
                                color={customColor}
                                valid={isValid}
                                type="text"/>
        {isCustom && <ColorItem color={customColor}/>}
    </Fragment>
}

export const ColorPicker = (props: ColorPickerProps) => {
    const [color, set] = useState(props.value)
    const setColor = useCallback((color) => {
        if (props.onChange) {
            props.onChange(color)
        }
        set(color)
    }, [set, props.onChange]);
    const memoizedContext = useMemo(() => ({
        selectedColor: color,
        setColor,
        isCustom: !props.colors.includes(color)
    }), [color, set])
    return (<ColorPickerContext.Provider value={memoizedContext}>
        <ColorPickerWrapper>
            <StyledColorPicker>
                {props.colors.map((c) => <ColorItem color={c} key={c}/>)}
            </StyledColorPicker>
            <ColorPickerInput></ColorPickerInput>
        </ColorPickerWrapper>
    </ColorPickerContext.Provider>)

}
