import styled from "styled-components";
import {animated} from "react-spring";

export const SectionHeader = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.theme.colorPrimary};
  border-bottom: 1px solid ${props => props.theme.colorDivider};
  margin-bottom: 12px;
`
export const SectionWrapper = animated(styled.div`
  margin-bottom: 16px;
  margin-top: 12px;
`)

export const SectionContent = styled.div`
`
