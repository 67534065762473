import styled from "styled-components";

const alignmentToFlex: (
  align?: "start" | "end" | "center"
) => "flex-start" | "flex-end" | "center" = (align) => {
  return (align
    ? align === "center"
      ? align
      : "flex-" + align
    : "flex-start") as "flex-start" | "flex-end" | "center";
};

export const Grid = styled.div<{ collapse?: boolean }>`
  display: grid;
  overflow: hidden;
  grid-gap: ${(props) => (props.collapse ? 0 : 32)}px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;
export const Row = styled.div<{ align?: "end" | "start" | "center" }>`
  display: flex;
  justify-content: center;
`;
export const Column = styled.div<{
  span?: number;
  justify?: "start" | "end" | "center" | "fill";
  align?: "start" | "end" | "center";
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => alignmentToFlex(props.align)};
  justify-content: ${(props) => props.justify === 'fill' ? 'stretch' : alignmentToFlex(props.justify)};
  grid-column: auto / span ${(props) => (props.span ? props.span : 12)};
`;
