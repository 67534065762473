import React, {Fragment, ReactNode, useState} from "react";
import styled from "styled-components";
import {lighten} from "polished";

const DynamicElement = styled.span`
  border-radius: 5px;

  &:after {
    font-family: "Inter", sans-serif;
    content: '}';
    margin: 0 4px;
    color: ${props => props.theme.colorDivider};
  }

  &:before {
    font-family: "Inter", sans-serif;
    content: '{';
    margin: 0 4px;
    color: ${props => props.theme.colorDivider};
  }

  background-color: #ebf2f5;
  padding: 4px 4px;

`
export const CookieProvider = (props: { children: string }) => {
    const [data] = useState(props.children)
    return <Fragment>{data.trim() === '{site}' ? <DynamicElement>site</DynamicElement> : data}</Fragment>
}
