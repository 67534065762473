import {useCallback, useEffect, useState} from "react";
import axios, {AxiosResponse} from "axios";
import {useAuthContext} from "../auth/auth";
import {Http} from "./client";
import {BASE_URL} from "../Constants";

export interface Customer {
    id: number;
    name: string;
    email: string
}

export interface ApiKey {
    apiKey: string;
    target: string;
}

export interface CreateApiKeyRequest {
    customer: number;
    target: string;
}

export interface CreateCustomerRequest {

}

export type Role = 'SUPERADMIN' | 'ADMIN';

export interface AdminAccount {
    name: string;
    role: Role;
}

export interface CreateAccountRequest {
    name: string;
    password: string;
    username: string;
    role: Role;
}

export interface CreateTargetRequest {
    name: string;
    displayName: string;
}

export type CreateCookieRequest = CookieValue

export class API {
    private static authorize(token: string) {
        return {headers: {Authorization: 'Bearer ' + token}};
    }

    static createTarget(request: CreateTargetRequest): Promise<AxiosResponse<Target>> {
        return Http.post(BASE_URL + '/v2/targets', request)
    }

    static createCookie(request: CreateCookieRequest): Promise<AxiosResponse<Target>> {
        return Http.post(BASE_URL + '/v2/cookies', request)
    }

    static createCustomer(request: CreateCustomerRequest, token: string): Promise<AxiosResponse<Customer>> {
        return Http.post(BASE_URL + '/v2/customers', request)
    }

    static createAccount(request: CreateAccountRequest): Promise<AxiosResponse<AdminAccount>> {
        return Http.post(BASE_URL + '/v2/accounts', request)
    }

    static createApiKey(request: CreateApiKeyRequest, token: string): Promise<AxiosResponse<ApiKey>> {
        return Http.post(BASE_URL + '/v2/apikeys', request)
    }
}

function useApiCall<T>(url: string, deps: any[] = []): { response: T | undefined, loading: boolean, setResponse: React.Dispatch<React.SetStateAction<T | undefined>> } {
    const {token} = useAuthContext()
    const [response, setResponse] = useState<T>()
    const [loading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const res = await Http.get<T>(url)
                if (res.status === 200) {
                    setResponse(res.data)
                }
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [token, ...deps])
    return {response, loading, setResponse}
}

function useApiCall2<T>(url: string, initial: T, deps: any[] = []): [response: T, loading: boolean, setResponse: React.Dispatch<React.SetStateAction<T>>] {
    const {token} = useAuthContext()
    const [response, setResponse] = useState<T>(initial)
    const [loading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const res = await Http.get<T>(url)
                if (res.status === 200) {
                    setResponse(res.data)
                }
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [token, ...deps])
    return [response, loading, setResponse]
}

function useApiCallSingle<T>(url: string, deps: any[] = []): [response: T | undefined, loading: boolean, setResponse: React.Dispatch<React.SetStateAction<T | undefined>>] {
    const {token} = useAuthContext()
    const [response, setResponse] = useState<T>()
    const [loading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const res = await Http.get<T>(url)
                if (res.status === 200) {
                    setResponse(res.data)
                }
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [token, ...deps])
    return [response, loading, setResponse]
}


export function useCustomers(deps: any[]) {
    const {response, loading} = useApiCall<Customer[]>(BASE_URL + '/v2/customers', deps)
    return {customers: response, loading}
}

export type Language = 'en' | 'fo' | 'da';
export type BannerType = 'Docked' | 'Floating' | 'FullWidth';

export interface Target {
    name: string;
    displayName: string;
    theme: {
        colorPrimary: string;
        colorAccent: string;
    }
    useToggle: boolean;
    languages: Language[];
    defaultLanguage: Language;
    bannerType: BannerType;
}

export function useTargets(deps: any[]) {
    const {response, loading} = useApiCall<Target[]>(BASE_URL + '/v2/targets', deps)
    return {targets: response, loading}
}


export function useAccounts(deps: any[]) {
    const {response, loading} = useApiCall<AdminAccount[]>(BASE_URL + '/v2/accounts', deps)
    return {accounts: response, loading}
}

export function useApiKeys(customerId: number, deps: any[]) {
    const {
        response,
        loading,
    } = useApiCall<ApiKey[]>(BASE_URL + '/v2/customers/' + customerId + '/apikeys', deps)
    return {apikeys: response, loading}
}

export function useTargetApiKeys(target: string, deps: any[]): [ApiKey[], boolean] {
    const [response, loading] = useApiCall2<ApiKey[]>(BASE_URL + '/v2/targets/' + target + '/apikeys', [], deps)
    return [response, loading];
}

export interface CookieValue {
    name: string;
    provider: string;
    description: {
        en: string | null;
        da: string | null;
        fo: string | null;
    }
    categories: string[];
    label: string | null;
}

export function useTargetCookies(target: string, deps: any[]): [CookieValue[], boolean] {
    const [response, loading] = useApiCall2<CookieValue[]>(BASE_URL + '/v2/targets/' + target + '/cookies', [], deps)
    return [response, loading];
}

export function useCookies(deps: any[]): [CookieValue[], boolean] {
    const [response, loading] = useApiCall2<CookieValue[]>(BASE_URL + '/v2/cookies', [], deps)
    return [response, loading];
}

export function useCustomer(id: number) {
    const {token} = useAuthContext()
    const [customer, set] = useState<Customer>()
    const [loading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            const response = await Http.get<Customer>(BASE_URL + '/v2/customers/' + id)
            if (response.status === 200) {
                set(response.data)
            }
            setIsLoading(false)
        }
        fetchData()
    }, [token, id])
    return {customer, loading}
}

export function useTarget(id: string, deps: any[]) {
    return useApiCallSingle<Target>(BASE_URL + '/v2/targets/' + id, deps)
}

