import React, {Fragment, useState} from "react";
import {Page} from "../../components/Page";
import {AdminAccount, Customer, useAccounts} from "../../api/api";
import {Button} from "../../components/Button";
import {useListTransition} from "../../globals";
import {List, ListItem} from "src/components/list";
import {AccountRole} from "./styles";
import {CreateAccountModal} from "./CreateAccountModal";

export const Accounts = () => {
    const [added, setAdded] = useState<AdminAccount[]>([])
    const {accounts, loading} = useAccounts([added])
    const [showCreate, setShowCreate] = useState(false)

    const transition = useListTransition((accounts ?? []), (acc) => acc.name)

    return <Fragment>
        <Page title="Accounts" loading={loading}>
            <Button onClick={() => setShowCreate(true)}>New Account</Button>
            <List>
                {transition((style, item) => (
                        item && (<ListItem key={item.name.toString()} style={style}>
                            <AccountRole>{item.role}</AccountRole>
                            {item.name}
                        </ListItem>)
                    )
                )}
            </List>
        </Page>
        <CreateAccountModal onHide={() => setShowCreate(false)}
                            postCreate={(acc) => setAdded([...added, acc])}
                            show={showCreate}/>
    </Fragment>
}
