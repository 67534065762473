import styled, {css, DefaultTheme} from "styled-components";
import {desaturate, lighten} from "polished";

const baseColor = (props: { secondary?: boolean, theme: DefaultTheme } & any) => props.secondary ? props.theme.colorPrimary : props.theme.colorAccent;
export const Button = styled.button<{ secondary?:boolean,borderless?: boolean }>`
  padding: 0 15px;
  min-width: ${props => props.borderless ? 0 : 200}px;
  height: 40px;
  border-radius: 5px;
  background-color: ${props => props.borderless ? 'transparent' : baseColor(props)};
  color: ${props => props.borderless ? baseColor(props) : '#fff'};
  font-family: Inter, sans-serif;
  font-size: 15px;
  flex: 1 1 auto;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out, border-color 100ms ease-in-out;
  ${props => props.borderless && css`
border: 1px solid transparent;
`} 
  &[disabled]{
    cursor:not-allowed;
    background-color: ${desaturate(1)('#d9029e')};
    &:hover{
      background-color: ${desaturate(1)('#d9029e')};
    }
  }
  &:hover{
      ${props => props.borderless ? css`
    //border: 1px solid ${props => props.theme.colorAccent}};
` : css`
    background-color: ${lighten(.1)('#d9029e')};
`}
  }
`;
